import { create } from "zustand";

// Mock API Calls (Replace with actual API)
const fetchPlantsAndWarehouses = async () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                plants: [
                    { id: "MFG-001", name: "Main Manufacturing Plant", warehouses: ["W-001", "W-002"] },
                    { id: "MFG-002", name: "Secondary Production Facility", warehouses: ["W-003", "W-004"] },
                    { id: "MFG-003", name: "Assembly Plant", warehouses: ["W-005", "W-006"] },
                ],
                warehouses: [
                    { id: "W-001", name: "Raw Materials Store", plantId: "MFG-001" },
                    { id: "W-002", name: "Finished Goods Store", plantId: "MFG-001" },
                    { id: "W-003", name: "Assembly Warehouse", plantId: "MFG-002" },
                    { id: "W-004", name: "Distribution Warehouse", plantId: "MFG-002" },
                    { id: "W-005", name: "Super Finished Goods Store", plantId: "MFG-003" },
                    { id: "W-006", name: "Super Goods Store", plantId: "MFG-003" },
                ],
            });
        }, 1000);
    });
};

// Mock API to Fetch BOM & Inventory Data
const fetchBOMData = async (selectedProducts) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                BOM: {
                    "Dining Table": {
                        parts: [
                            {
                                id: "P001",
                                name: "Table Top",
                                subParts: [
                                    { id: "S001", name: "Wood Panel" },
                                    { id: "S002", name: "Wood Finish" },
                                ],
                            },
                            {
                                id: "P002",
                                name: "Table Leg",
                                subParts: [
                                    { id: "S003", name: "Wood Block" },
                                    { id: "S004", name: "Bush" },
                                ],
                            },
                        ],
                    },
                    "Office Desk": {
                        parts: [
                            {
                                id: "P003",
                                name: "Desk Top",
                                subParts: [{ id: "S005", name: "Wood Panel" }],
                            },
                            {
                                id: "P004",
                                name: "Desk Leg",
                                subParts: [{ id: "S006", name: "Metal Tube" }],
                            },
                        ],
                    },
                },
                inventory: {
                    "Wood Panel": {
                        required: 50, projected: 40, toOrder: 10,
                        details: {
                            "Feb 2025": { actual: 36, planned: 10, requested: 15, ordered: 29, reserved: 5, prod: 3, sub: 1, plan: 3, expired: 2 },
                            "Mar 2025": { actual: 6, planned: 2, requested: 15, ordered: 14, reserved: 12, prod: 8, sub: 3, plan: 2, expired: 0 },
                            "Apr 2025": { actual: 42, planned: 28, requested: 9, ordered: 22, reserved: 8, prod: 7, sub: 3, plan: 7, expired: 1 },
                        }
                    },
                    "Wood Finish": {
                        required: 30, projected: 20, toOrder: 10,
                        details: {
                            "Feb 2025": { actual: 25, planned: 12, requested: 8, ordered: 14, reserved: 4, prod: 2, sub: 1, plan: 1, expired: 0 },
                            "Mar 2025": { actual: 12, planned: 5, requested: 12, ordered: 10, reserved: 6, prod: 3, sub: 2, plan: 1, expired: 1 },
                        }
                    },
                }
            });
        }, 1000);
    });
};

const initialState = {
    plants: [],
    warehouses: [],
    selectedPlants: [],
    availableWarehouses: [],
    selectedWarehouses: [],
    planningData: [],
    isLoading: true,

    // Store multiple product selections
    planningDetails: [],

    // BOM & Inventory Data
    bomData: {},
    inventoryData: {},

    // Month-wise stock summary
    stockSummary: {},
};

const usePlanningBomStore = create((set, get) => ({
    ...initialState,

    fetchData: async () => {
        const data = await fetchPlantsAndWarehouses();
        set({
            plants: data.plants,
            warehouses: data.warehouses,
            isLoading: false,
        });
    },

    setSelectedPlants: (plants) => {
        const allWarehouses = get().warehouses;
        const filteredWarehouses = allWarehouses.filter((wh) =>
            plants.some((plant) => wh.plantId === plant)
        );

        const selectedWarehouses = get().selectedWarehouses.filter((whId) =>
            filteredWarehouses.some((wh) => wh.id === whId)
        );

        set({
            selectedPlants: plants,
            availableWarehouses: filteredWarehouses,
            selectedWarehouses,
        });
    },

    setSelectedWarehouses: (warehouses) => {
        const validWarehouses = warehouses.filter((wh) =>
            get().availableWarehouses.some((aw) => aw.id === wh)
        );
        set({ selectedWarehouses: validWarehouses });
    },

    fetchBOMAndInventory: async (selectedProducts) => {
        const data = await fetchBOMData(selectedProducts);
        set({
            bomData: data.BOM,
            inventoryData: data.inventory,
        });

        // Generate month-wise stock summary
        const stockSummary = get().generateStockSummary(data.inventory);
        set({ stockSummary });
    },

    /**
     * ✅ Generate month-wise stock summary for inventory details
     */
    generateStockSummary: (inventory) => {
        const months = ["Feb 2025", "Mar 2025", "Apr 2025", "May 2025", "Jun 2025", "Jul 2025"];
        let summary = {};

        months.forEach((month) => {
            summary[month] = {
                available: {
                    actual: 0,
                    planned: 0,
                    requested: 0,
                    ordered: 0,
                },
                reserved: {
                    total: 0,
                    product: 0,
                    sub: 0,
                    plan: 0,
                },
                expiredStock: 0,
            };

            Object.values(inventory).forEach((item) => {
                if (item.details?.[month]) {
                    summary[month].available.actual += item.details[month].actual || 0;
                    summary[month].available.planned += item.details[month].planned || 0;
                    summary[month].available.requested += item.details[month].requested || 0;
                    summary[month].available.ordered += item.details[month].ordered || 0;

                    summary[month].reserved.total += item.details[month].reserved || 0;
                    summary[month].reserved.product += item.details[month].prod || 0;
                    summary[month].reserved.sub += item.details[month].sub || 0;
                    summary[month].reserved.plan += item.details[month].plan || 0;

                    summary[month].expiredStock += item.details[month].expired || 0;
                }
            });
        });

        return summary;
    },

    setPlanningDetails: (newDetails, reset = false) => {
        set((state) => ({
            planningDetails: reset
                ? newDetails
                : [...state.planningDetails, ...newDetails].filter(
                      (v, i, a) => a.findIndex((t) => t.selectedItem === v.selectedItem) === i
                  ),
        }));
    },

    resetPlanningBomStore: () => set(initialState),
}));

export default usePlanningBomStore;
