// import {
//   faChartBar,
//   faSignOutAlt,
//   faTimes,
//   faMagnifyingGlass,
//   faFileAlt,
//   faIndustry,
//   faGlobe,
//   faCommentDots,
//   faPeopleArrows,
//   faCalculator,
//   faUserGroup,
//   faBoxesStacked,
//   faRectangleList,
//   faMapSigns,
//   faCreditCard,
//   faBook,
//   faVialVirus,
//   faGift,
//   faAdd,
// } from "@fortawesome/free-solid-svg-icons";
// import { URLs } from "../../routes";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   Alert,
//   Button,
//   Card,
//   CircularProgress,
//   Fab,
//   Grid,
//   Typography,
//   useMediaQuery,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { getPlansListAPIerp } from "../../api/BuyPlansAPI";
// import { Navigate, useNavigate } from "react-router-dom";

// const ProductListBP = () => {
//   const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");
//   const navigate = useNavigate();
//   const [plansList, setPlanslist] = useState(null);

//   useEffect(() => {
//     getPlansListF();
//   }, []);

//   const getPlansListF = async () => {
//     try {
//       const res = await getPlansListAPIerp();
//       // console.log(res);
//       if (res.status == 200) {
//         let temp = res.data.plan_list;
//         let temp2 = temp.map((t) => {
//           let t1 = t;
//           t1["isInCart"] = false;
//           return t1;
//         });

//         setPlanslist(temp2);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleAdd2Cart = (plan) => {
//     // console.log(plan);

//     let temp1 = plansList;
//     let temp2 = temp1.map((p) => {
//       if (p.name !== plan.name) {
//         return p;
//       } else {
//         p["isInCart"] = true;
//         return p;
//       }
//     });
//     setPlanslist(temp2);
//   };
//   const handleRemove4mCart = (plan) => {
//     // console.log(plan);
//     let temp1 = plansList;
//     let temp2 = temp1.map((p) => {
//       if (p.name !== plan.name) {
//         return p;
//       } else {
//         p["isInCart"] = false;
//         return p;
//       }
//     });
//     setPlanslist(temp2);
//   };
//   const handle2Checkout = () => {
//     let buyList = plansList.filter((p) => p.isInCart === true);
//     navigate(URLs.CheckoutPlanURL.path, {
//       state: {
//         data: {
//           buy_now: buyList,
//         },
//       },
//     });
//   };
//   return (
//     <div style={{ minHeight: "87vh", margin: "20px" }}>
//       {plansList === null && (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             flexDirection: "column",
//             height: "100vh",
//           }}
//         >
//           <div>
//             <CircularProgress size={150} color="inherit" />
//           </div>
//           <div>
//             <Alert icon={false} severity="warning">
//               <h3>Loading...</h3>
//             </Alert>
//           </div>
//         </div>
//       )}
//       <Grid container spacing={3}>
//         <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
//         <Grid item lg={10} md={10} sm={12} xs={12}>
//           <Grid container spacing={3}>
//             {plansList?.map((plan, index) => {
//               return (
//                 <Grid item lg={3} md={4} sm={12} xs={12} key={index}>
//                   <Card
//                     sx={{
//                       border: "1px solid #ccc",
//                       borderRadius: "15px",
//                       boxShadow: "0 10px 10px rgba(0, 0, 0, 0.3)",
//                       padding: "20px",
//                       marginBottom: isExtraSmallScreen ? "15px" : "0",
//                       // width: "250px",
//                       minHeight: "180px",
//                       // borderLeft: "5px solid #00c875",
//                       backgroundColor:
//                         plan.isInCart === false ? "#fff" : "#00c875",
//                       display: "flex",
//                       justifyContent: "space-evenly",
//                       alignItems: "center",
//                       flexDirection: "column",
//                     }}
//                   >
//                     <div>
//                       <FontAwesomeIcon
//                         style={{
//                           width: "50px",
//                           height: "50px",
//                         }}
//                         icon={
//                           plan.item === "ERP Implementation"
//                             ? faGift
//                             : plan.item === "Receipts"
//                             ? faFileAlt
//                             : plan.item === "Lab Management"
//                             ? faVialVirus
//                             : plan.item === "Notes"
//                             ? faBook
//                             : plan.item === "O&M Inspection"
//                             ? faMagnifyingGlass
//                             : plan.item === "Product Bundling"
//                             ? faUserGroup
//                             : plan.item === "Calculator"
//                             ? faCalculator
//                             : plan.item === "Intelligent Reporting"
//                             ? faChartBar
//                             : plan.item === "Chatbot"
//                             ? faCommentDots
//                             : plan.item === "Resource Allocation"
//                             ? faUserGroup
//                             : plan.item === "Digital Forms"
//                             ? faRectangleList
//                             : plan.item === "Visiting Cards"
//                             ? faFileAlt
//                             : plan.item === "Make Payments"
//                             ? faCreditCard
//                             : plan.item === "Dashboards"
//                             ? faChartBar
//                             : plan.item === "SDS"
//                             ? faFileAlt
//                             : faGift
//                         }
//                       />
//                     </div>
//                     <div
//                       style={{
//                         textAlign: "center",
//                       }}
//                     >
//                       <Typography style={{fontFamily:"Rubik" , fontSize:"20px"}}> {plan.plan_name}</Typography>
//                     </div>

//                     <div>
//                       {plan.isInCart === false ? (
//                         <Button
//                           style={{ color: "#050092",fontFamily:"Rubik" }}
//                           onClick={() => handleAdd2Cart(plan)}
//                           variant="outlined"
//                         >
//                           <FontAwesomeIcon icon={faAdd} style={{marginRight:"4px"}} /> Add
//                         </Button>
//                       ) : (
//                         <Button
//                           style={{ color: "#fff", backgroundColor: "#050092" }}
//                           onClick={() => handleRemove4mCart(plan)}
//                           variant="outlined"
//                         >
//                           Remove
//                         </Button>
//                       )}
//                     </div>
//                   </Card>
//                 </Grid>
//               );
//             })}
//           </Grid>
//         </Grid>
//         <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
//       </Grid>
//       {plansList?.filter((p) => p.isInCart === true).length >= 1 && (
//         <Fab
//           color="primary"
//           aria-label="next"
//           style={{
//             position: "fixed",
//             bottom: "60px",
//             right: "16px",
//           }}
//           onClick={() => handle2Checkout()}
//         >
//           <ArrowForwardIosIcon />
//         </Fab>
//       )}
//     </div>
//   );
// };

// export default ProductListBP;

import {
  faChartBar,
  faSignOutAlt,
  faTimes,
  faMagnifyingGlass,
  faFileAlt,
  faIndustry,
  faGlobe,
  faCommentDots,
  faPeopleArrows,
  faCalculator,
  faUserGroup,
  faBoxesStacked,
  faRectangleList,
  faMapSigns,
  faCreditCard,
  faBook,
  faVialVirus,
  faGift,
  faAdd,
  faListCheck
} from "@fortawesome/free-solid-svg-icons";
import { URLs } from "../../routes";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Card,
  CircularProgress,
  Fab,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPlansListAPIerp } from "../../api/BuyPlansAPI";
import { Navigate, useNavigate } from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const ProductListBP = () => {
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");
  const navigate = useNavigate();
  const [plansList, setPlanslist] = useState(null);

  useEffect(() => {
    getPlansListF();
  }, []);

  const getPlansListF = async () => {
    try {
      const res = await getPlansListAPIerp();
      if (res.status === 200) {
        let temp = res.data.plan_list;
        let temp2 = temp.map((t) => {
          let t1 = t;
          t1["isInCart"] = false;
          return t1;
        });

        setPlanslist(temp2);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd2Cart = (plan) => {
    let temp1 = plansList;
    let temp2 = temp1.map((p) => {
      if (p.name !== plan.name) {
        return p;
      } else {
        p["isInCart"] = true;
        return p;
      }
    });
    setPlanslist(temp2);
  };

  const handleRemove4mCart = (plan) => {
    let temp1 = plansList;
    let temp2 = temp1.map((p) => {
      if (p.name !== plan.name) {
        return p;
      } else {
        p["isInCart"] = false;
        return p;
      }
    });
    setPlanslist(temp2);
  };

  const handle2Checkout = () => {
    let buyList = plansList.filter((p) => p.isInCart === true);
    navigate(URLs.CheckoutPlanURL.path, {
      state: {
        data: {
          buy_now: buyList,
        },
      },
    });
  };

  return (
    <div style={{ minHeight: "87vh", margin: "20px" }}>
      {plansList === null && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <div>
            <CircularProgress size={150} color="inherit" />
          </div>
          <div>
            <Alert icon={false} severity="warning">
              <h3>Loading...</h3>
            </Alert>
          </div>
        </div>
      )}
      <Grid container spacing={3}>
        <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
        <Grid item lg={10} md={10} sm={12} xs={12}>
          <Grid container spacing={3}>
            {plansList?.map((plan, index) => {
              return (
                <Grid item lg={3} md={4} sm={12} xs={12} key={index}>
                  <Card
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "15px",
                      boxShadow: "0 10px 10px rgba(0, 0, 0, 0.3)",
                      padding: "20px",
                      marginBottom: isExtraSmallScreen ? "15px" : "0",
                      minHeight: "180px",
                      backgroundColor:
                        plan.isInCart === false ? "#fff" : "#00c875",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* Left Section */}
                    <div style={{ textAlign: "left", flex: 1 }}>
                      <Typography
                        style={{
                          fontFamily: "Rubik",
                          fontSize: "18px",
                          marginBottom: "24px",
                        }}
                      >
                        {plan.plan_name}
                      </Typography>
                      {plan.isInCart === false ? (
                        <Button
                          style={{ color: "#050092", fontFamily: "Rubik" }}
                          onClick={() => handleAdd2Cart(plan)}
                          variant="outlined"
                        >
                          <ShoppingCartIcon
                          outlined
                            icon={faAdd}
                            style={{ marginRight: "4px" }}
                          />{" "}
                          Add
                        </Button>
                      ) : (
                        <Button
                          style={{
                            color: "#fff",
                            backgroundColor: "#050092",
                          }}
                          onClick={() => handleRemove4mCart(plan)}
                          variant="outlined"
                        >
                          Remove
                        </Button>
                      )}
                    </div>

                    {/* Right Section */}
                    <div style={{ textAlign: "center", flex: 0.4 }}>
                      <FontAwesomeIcon
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                        icon={
                          plan.item === "ERP Implementation"
                            ? faGift
                            : plan.item === "Receipts"
                            ? faFileAlt
                            : plan.item === "Lab Management"
                            ? faVialVirus
                            : plan.item === "Notes"
                            ? faBook
                            : plan.item === "O&M Inspection"
                            ? faMagnifyingGlass
                            : plan.item === "Product Bundling"
                            ? faUserGroup
                            : plan.item === "Calculator"
                            ? faCalculator
                            : plan.item === "Intelligent Reporting"
                            ? faChartBar
                            : plan.item === "Chatbot"
                            ? faCommentDots
                            : plan.item === "Resource Allocation"
                            ? faUserGroup
                            : plan.item === "Digital Forms"
                            ? faRectangleList
                            : plan.item === "Visiting Cards"
                            ? faFileAlt
                            : plan.item === "Make Payments"
                            ? faCreditCard
                            : plan.item === "Dashboards"
                            ? faChartBar
                            : plan.item === "SDS"
                            ? faFileAlt
                            : plan.item === "Timesheet"
                            ? faListCheck
                            : faGift
                        }
                      />
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
      </Grid>
      {plansList?.filter((p) => p.isInCart === true).length >= 1 && (
        <Fab
          color="primary"
          aria-label="next"
          style={{
            position: "fixed",
            bottom: "60px",
            right: "16px",
          }}
          onClick={() => handle2Checkout()}
        >
          <ArrowForwardIosIcon />
        </Fab>
      )}
    </div>
  );
};

export default ProductListBP;
