import {
    Box, Button, FormControl, MenuItem, Paper, Select,
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TextField, Typography, CircularProgress
} from '@mui/material';
import React, { useEffect } from 'react';
import useMrpStore from '../../stores/mrp/mrpStore';
import useProductSelectionStore from '../../stores/mrp/productSelectionStore';
import usePlanningBomStore from "../../stores/mrp/planningBomStore";

const ProductSelection = () => {
    const { setCurrentScreen } = useMrpStore();
    const {
        selectedItem, setSelectedItem, planningType, setPlanningType,
        demandData, setDemandData, fetchData, fetchSalesOrders,
        products, planningTypes, salesOrders, isLoadingProducts, isLoadingSales, columns
    } = useProductSelectionStore();

    useEffect(() => {
        fetchData(); // Fetch products & planning types on mount
    }, []);

    useEffect(() => {
        if (selectedItem) {
            fetchSalesOrders(); // Fetch sales orders when product OR planningType changes
        }
    }, [selectedItem, planningType]); // <-- Added planningType as a dependency


    const handleDemandChange = (period, value) => {
        setDemandData({ ...demandData, [period]: parseInt(value) || 0 });
    };

    const handleProceed2Planning = () => {
        const { selectedItem, planningType, salesOrders, demandData, totalOrders } = useProductSelectionStore.getState();
        const { setPlanningDetails } = usePlanningBomStore.getState();

        // Hardcoded test products (for debugging)
        const testProducts = [
            {
                selectedItem: "FG111 - Sofa Set (PCS)",
                planningType: "Monthly",
                salesOrders: { "Jan 2025": 50, "Feb 2025": 60, "Mar 2025": 70 },
                demandData: { "Jan 2025": 40, "Feb 2025": 50, "Mar 2025": 60 },
                totalOrders: { "Jan 2025": 90, "Feb 2025": 110, "Mar 2025": 130 },
            },
        ];

        // Merge live selected product with test data
        setPlanningDetails(
            [
                {
                    selectedItem,
                    planningType,
                    salesOrders,
                    demandData,
                    totalOrders,
                },
                ...testProducts, // Add test products for debugging
            ],
            true // Reset previous selections
        );

        console.log("Stored Planning Details:", [
            {
                selectedItem,
                planningType,
                salesOrders,
                demandData,
                totalOrders,
            },
            ...testProducts,
        ]);

        setCurrentScreen("Planning BOM");
    };

    if (isLoadingProducts) {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
        </Box>;
    }

    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h6" fontWeight="small" style={{ fontFamily: "Rubik" }}>
                    Select Finished Good
                </Typography>
                <FormControl margin="normal" style={{ width: "20%" }}>
                    <Select
                        style={{ border: "0px solid", borderRadius: "18px", fontFamily: "Rubik" }}
                        value={selectedItem}
                        onChange={(e) => setSelectedItem(e.target.value)}
                    >
                        {products.map((product) => (
                            <MenuItem key={product.id} value={`${product.id} - ${product.name}`}>
                                {product.id} - {product.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h6" style={{ fontFamily: "Rubik" }}>
                    Planning for: {selectedItem ? selectedItem.split(" - ")[1] : "Select a product"}
                </Typography>
                <Box display="flex" gap={2} alignItems="center">
                    <FormControl>
                        <Select
                            style={{ border: "0px solid", borderRadius: "12px", fontFamily: "Rubik" }}
                            value={planningType}
                            onChange={(e) => setPlanningType(e.target.value)}
                            size="small"
                        >
                            {planningTypes.map((type) => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        style={{ border: "0px solid", borderRadius: "12px", fontFamily: "Rubik", backgroundColor: "#050092",color:"white" }}
                        variant="contained"
                        onClick={() => { handleProceed2Planning(); }}
                        disabled={!selectedItem}
                    >
                        Proceed
                    </Button>
                </Box>
            </Box>

            <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: "auto" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontFamily:"Rubik"}}>PERIOD</TableCell>
                            {columns.map((col) => (
                                <TableCell key={col}>{col}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{fontFamily:"Rubik"}}>Demand</TableCell>
                            {columns.map((col) => (
                                <TableCell key={col} style={{fontFamily:"Rubik"}}>
                                    <TextField
                                        type="number"
                                        size="small"
                                        value={demandData[col] || ""}
                                        onChange={(e) => handleDemandChange(col, e.target.value)}
                                        sx={{fontFamily:"Rubik", width:"80px"}}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>

                        <TableRow>
                            <TableCell style={{fontFamily:"Rubik"}}>Sales Orders</TableCell>
                            {isLoadingSales ? (
                                <TableCell colSpan={6} align="center" >
                                    <CircularProgress size={20} />
                                </TableCell>
                            ) : (
                                columns.map((col) => (
                                    <TableCell key={col} style={{fontFamily:"Rubik"}} >{salesOrders[col] || 0}</TableCell>
                                ))
                            )}
                        </TableRow>

                        <TableRow>
                            <TableCell style={{fontFamily:"Rubik"}}>Total</TableCell>
                            {columns.map((col) => (
                                <TableCell key={col} style={{fontFamily:"Rubik"}}>
                                    {(demandData[col] || 0) + (salesOrders[col] || 0)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ProductSelection;
