import { create } from "zustand";

// Initial State
const initialState = {
    // Screen State
    currentScreen: "Product Selection",
    // currentScreen: ["Product Selection","Planning BOM","Final Overview","Mrp Summary"]
};

const useMrpStore = create((set) => ({
    ...initialState, // Spread initial state

    // Screen Management
    setCurrentScreen: (screen) => set({ currentScreen: screen }),

    // Reset Store to Initial State
    resetStore: () => set(initialState),
}));

export default useMrpStore;
