import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

const ODD_OPACITY = 0.2;

const StripedDataGridBase = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  // Ensure text wrapping and proper visibility for all cells
  [`& .${gridClasses.cell}`]: {
    whiteSpace: "normal", // Allows text to wrap
    wordBreak: "break-word", // Breaks long words if necessary
    lineHeight: "1.2", // Adjusts line spacing
    overflow: "hidden",
    textOverflow: "ellipsis", // Adds ellipsis for overflowing text
  },
  // Ensure consistent styling for the root
  [`& .MuiDataGrid-root`]: {
    density: "comfortable", // Optional: Set default density
  },
}));


export default StripedDataGridBase;
