// stores/finalOverviewStore.js
import { create } from "zustand";

// Initial State
const initialState = {
    finalToBeOrdered: [
        {
            item: "FOAM-001 - Seat Foam",
            unit: "PCS",
            totalRequired: 321,
            totalProjected: 299,
            totalOrder: 80,
            leadTime: "2 weeks",
            status: "Critical",
            details: [
                { period: "FEB 2025", required: 68, projected: 23, order: 45, status: "Critical" },
                { period: "MAR 2025", required: 28, projected: 73, order: 0, status: "Normal" },
                { period: "APR 2025", required: 42, projected: 50, order: 0, status: "Normal" },
                { period: "MAY 2025", required: 59, projected: 30, order: 29, status: "Warning" },
                { period: "JUN 2025", required: 52, projected: 46, order: 6, status: "Normal" },
                { period: "JUL 2025", required: 72, projected: 77, order: 0, status: "Normal" },
            ],
        },
        {
            item: "FOAM-003 - Office Chair",
            unit: "PCS",
            totalRequired: 543,
            totalProjected: 43,
            totalOrder: 500,
            leadTime: "3 weeks",
            status: "Critical",
            details: [
                { period: "FEB 2025", required: 68, projected: 23, order: 45, status: "Critical" },
                { period: "MAR 2025", required: 28, projected: 73, order: 0, status: "Normal" },
                { period: "APR 2025", required: 42, projected: 50, order: 0, status: "Normal" },
                { period: "MAY 2025", required: 59, projected: 30, order: 29, status: "Warning" },
                { period: "JUN 2025", required: 52, projected: 46, order: 6, status: "Normal" },
                { period: "JUL 2025", required: 72, projected: 77, order: 0, status: "Normal" },
            ],
        },
    ],
    expandedRows: {},
};

const useFinalOverviewStore = create((set) => ({
    ...initialState, // Spread initial state

    // Actions
    toggleRow: (index) => set((state) => ({
        expandedRows: {
            ...state.expandedRows,
            [index]: !state.expandedRows[index],
        },
    })),

    handleOrderChange: (index, detailIndex, newValue) => set((state) => {
        const updatedData = [...state.finalToBeOrdered];
        updatedData[index].details[detailIndex].order = Number(newValue);
        return { finalToBeOrdered: updatedData };
    }),

    // Reset Store to Initial State
    resetFinalOverviewStore: () => set(initialState),
}));

export default useFinalOverviewStore;