import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faSignOutAlt,
  faTimes,
  faMagnifyingGlass,
  faFileAlt,
  faIndustry,
  faGlobe,
  faCommentDots,
  faPeopleArrows,
  faCalculator,
  faUserGroup,
  faBoxesStacked,
  faRectangleList,
  faMapSigns,
  faCreditCard,
  faBook,
  faVialVirus,
  faTimesCircle,
  faTimeline,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import "./Sidebar.css";
import smallM from "../assets/img/technologies/apple-touch-icon.png";

import { URLs } from "../routes";
import ProfilePicture from "../assets/img/team/picture_profile.png";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/MoreVert";
import { Accordion } from "react-bootstrap";
import packageJson from "../../package.json";
import { axiosInstance } from "../axiosSetup";
import { useSelector } from "react-redux";
import { getBackendVersionAPIerp } from "../api/auth";

const appVersion = packageJson.version;

const Sidebar = (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const onCollapse = () => setShow(!show);
  const userEmail = localStorage.getItem("user_email");
  const navigate = useNavigate();
  const logo =
    localStorage.getItem("company_logo") &&
      localStorage.getItem("company_logo") !== "null"
      ? `${localStorage.getItem("base_url")}${localStorage.getItem(
        "company_logo"
      )}`
      : smallM;
  const companyName =
    localStorage.getItem("company") && localStorage.getItem("company") !== null
      ? localStorage.getItem("company")
      : "Mannlowe";

  const { connected_apps } = useSelector((state) => state.connectedAccountData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);
  useEffect(() => {
    getBackendVersion();
  }, []);

  const getBackendVersion = async () => {
    try {
      const res = await getBackendVersionAPIerp();
      // console.log(res);

      let temp = res.data.apps.filter((item) => item.app_name === "frontend");
      setLatestVersion(temp[0].version);
    } catch (error) {
      console.log(error);
    }
  };
  const clearCacheAndReload = async () => {
    try {
      // Clear cache
      await caches.keys().then((keys) => {
        return Promise.all(keys.map((key) => caches.delete(key)));
      });

      // Unregister service worker
      if ("serviceWorker" in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        registrations.forEach((registration) => {
          registration.unregister();
        });
      }

      // Refresh the page to register a new service worker
      window.location.reload(true);
    } catch (error) {
      console.error("Error clearing cache:", error);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCompanySettingsOnclick = () => {
    navigate(URLs.CompanySettingsURL.path);
    handleClose();
  };
  const handleUpdateButtonClick = () => {
    clearCacheAndReload();
  };
  const handlePlansPricingOnclick = () => {
    navigate(URLs.MyPlanPlans.path);
    handleClose();
  };

  const handleConnectedAccountsOnclick = () => {
    navigate(URLs.ConnectedAccounts.path);
    handleClose();
  };
  const handleLogout = () => {
    navigate(URLs.Logout.path);
  };

  const getUserInitials = () => {
    if (userEmail) {
      const initials = userEmail
        .split("@")[0] // Get the part before '@'
        .charAt(0) // Get the first character
        .toUpperCase(); // Convert to uppercase
      return initials;
    }
    return "";
  };

  let subscribed_products = [];
  try {
    subscribed_products = JSON.parse(
      localStorage.getItem("subscribed_products")
    );
  } catch {
    console.log("Subscribed Products list empty");
  }
  let dashboard_setup = {};
  try {
    dashboard_setup = JSON.parse(localStorage.getItem("metadata"))["dashboard"];
    if (dashboard_setup) {
    } else {
      dashboard_setup = {};
    }
  } catch {
    console.log("Subscribed Products list empty");
  }

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              {icon && (
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={icon} />{" "}
                </span>
              )}
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Accordion className="flex-column">{children}</Accordion>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };
  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
      underCollapse = false,
      toOpenWithSession = false,
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName =
      link === decodeURIComponent(pathname) ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    function splitTitle(title) {
      const maxCharsPerLine = 22; // Adjust this value based on your requirement
      const words = title.split(" ");

      if (title.length <= maxCharsPerLine || words.length === 1) {
        return title;
      }

      let lines = [];
      let currentLine = "";

      for (let i = 0; i < words.length; i++) {
        const word = words[i];

        if (word.length > maxCharsPerLine) {
          // If the word itself is longer than the maxCharsPerLine, break it into multiple lines
          lines.push(
            <span className="d-block" key={i}>
              {word}
            </span>
          );
          currentLine = "";
        } else {
          const potentialLine = currentLine ? `${currentLine} ${word}` : word;

          if (potentialLine.length <= maxCharsPerLine) {
            currentLine = potentialLine;
          } else {
            lines.push(
              <span className="d-block" key={i}>
                {currentLine}
              </span>
            );
            currentLine = word;
          }
        }
      }

      if (currentLine.length > 0) {
        lines.push(
          <span className="d-block" key={words.length}>
            {currentLine}
          </span>
        );
      }

      return lines;
    }

    const handleLabManagementClick = () => {
      const labManagement = connected_apps.hasOwnProperty("LabManagement")
        ? connected_apps["LabManagement"]
        : null;
      if (labManagement.credential_available === "YES") {
        const loginUrl = `${labManagement["base_url"]}${labManagement["redirecting_url"]}?usr=${labManagement["email"]}&sid=${labManagement["sid"]}`;
        // Open a new tab
        const newTab = window.open(loginUrl, "_blank");
      }
      if (labManagement.credential_available === "NO") {
        navigate("/redirecting-error", {
          state: {
            data: {
              connect2: labManagement.connect2,
              credential_available: "NO",
              productName: "LabManagement",
            },
          },
        });
      }
    };
    const handleERPManagementClick = () => {
      const erpManagement = connected_apps.hasOwnProperty("ERP")
        ? connected_apps["ERP"]
        : null;
      if (erpManagement) {
        const loginUrl = `${erpManagement["base_url"]}${erpManagement["redirecting_url"]}?usr=${erpManagement["email"]}&sid=${erpManagement["sid"]}`;
        // Open a new tab
        const newTab = window.open(loginUrl, "_blank");
      }
    };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link
          {...linkProps}
          target={target}
          className={classNames}
          onClick={(e) => {
            if (title === "Formulation, R&D Labs") {
              handleLabManagementClick();
            }
            if (title === "ERP Website") {
              handleERPManagementClick();
            }
          }}
        >
          <span>
            <span className="sidebar-text">
              <span className="d-flex align-items-center">
                {icon ? (
                  <span className="sidebar-icon">
                    <FontAwesomeIcon icon={icon} />{" "}
                  </span>
                ) : null}
                {image ? (
                  <Image
                    src={image}
                    width={20}
                    height={20}
                    className="sidebar-icon svg-icon"
                  />
                ) : null}
                <span className="ms-2">{splitTitle(title)}</span>
              </span>
              {badgeText ? (
                <Badge
                  pill
                  bg={badgeBg}
                  text={badgeColor}
                  className="badge-md notification-count ms-2"
                >
                  {badgeText}
                </Badge>
              ) : null}
            </span>
          </span>
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner-container">
            <div className="sidebar-inner px-4 pt-3">
              <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                <div className="d-flex align-items-center">
                  <div className="user-avatar lg-avatar me-4">
                    <Image
                      src={ProfilePicture}
                      className="card-img-top rounded-circle border-white"
                    />
                  </div>
                  <div className="d-block">
                    <h6>Hi, {localStorage.getItem("user_email")}</h6>
                    <Button
                      as={Link}
                      variant="secondary"
                      size="xs"
                      to={URLs.Signin.path}
                      className="text-dark"
                    >
                      <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                      Sign Out
                    </Button>
                  </div>
                </div>
                <Nav.Link
                  className="collapse-close d-md-none"
                  onClick={onCollapse}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Nav.Link>
              </div>

              <Nav className="flex-column pt-3 pt-md-0">
                <NavItem
                  external
                  title={companyName}
                  link=""
                  target="_blank"
                  image={logo}
                  className="logo"
                />
                <Dropdown.Divider className="my-3 border-indigo" />
                {/* <NavItem
                title="Reports"
                link={URLs.Admin.path}
                icon={faTv}
                className="d-block"
              />
              <Dropdown.Divider className="my-3 border-indigo" /> */}
                <NavItem
                  title="Overview"
                  link={URLs.Overview.path}
                  icon={faChartBar}
                  className="text-center logo"
                />
                {subscribed_products.indexOf("Dashboards") !== -1 &&
                  dashboard_setup.hasOwnProperty("dashboards") &&
                  dashboard_setup?.dashboards?.length !== 0 && (
                    <CollapsableNavItem
                      eventKey="/dashboard/"
                      title="Dashboard"
                      icon={faChartBar}
                    >
                      {dashboard_setup.dashboards.map((dashboard, index) => {
                        return (
                          <NavItem
                            key={`dashboard_${index}`}
                            title={dashboard.label}
                            link={`/dashboard/${dashboard.label}`}
                            // icon={faChartBar}
                            className="text-center logo"
                            underCollapse={true}
                          />
                        );
                      })}
                    </CollapsableNavItem>
                  )}
                {subscribed_products.indexOf("SDS") !== -1 && (
                  <CollapsableNavItem
                    eventKey="business-automation/"
                    title="Business Automation"
                    icon={faFileAlt}
                  >
                    <NavItem title="Documents" link={URLs.SDS.path} />
                    {subscribed_products.indexOf("Visiting Cards") !== -1 && (
                      <NavItem
                        title="Visiting Cards"
                        link={URLs.Exhibition.path}
                        // icon={}
                        className="text-center logo"
                      />
                    )}
                    {subscribed_products.indexOf("SDS-TEST") !== -1 && (
                      <NavItem title="Test" link={URLs.TestDoc.path} />
                    )}
                  </CollapsableNavItem>
                )}
                {subscribed_products.indexOf("Visiting Cards") !== -1 && (
                  <CollapsableNavItem
                    eventKey="exhibition/"
                    title="Exhibition"
                    icon={faMapSigns}
                  >
                    <NavItem
                      title="Visiting Cards"
                      link={URLs.Exhibition.path}
                      // icon={}
                      className="text-center logo"
                    />
                    {/* {subscribed_products.indexOf("Receipts") !== -1 && (
                      <NavItem
                        title="Receipts"
                        link={URLs.ExhibitionReceipt.path}
                        // icon={}
                        className="text-center logo"
                      />
                    )} */}
                  </CollapsableNavItem>
                )}
                {subscribed_products.indexOf("Intelligent Reporting") !==
                  -1 && (
                    <NavItem
                      title="Intelligent Reporting"
                      link={URLs.IntelligentReporting.path}
                      icon={faChartBar}
                    />
                  )}
                {subscribed_products.indexOf("Chatbot") !== -1 && (
                  <NavItem
                    title="Chatbot"
                    link={URLs.ChatbotURL.path}
                    icon={faCommentDots}
                  />
                )}
                {subscribed_products.indexOf("Calculator") !== -1 && (
                  <NavItem
                    title="Calculator"
                    link={URLs.Calculator.path}
                    icon={faCalculator}
                    className="text-center logo"
                  />
                )}
                {subscribed_products.indexOf("Digital Forms") !== -1 && (
                  <NavItem
                    title="Digital Forms"
                    link={URLs.digitalFormURL.path}
                    icon={faRectangleList}
                    className="text-center logo"
                  />
                )}
                {/* {subscribed_products.indexOf("Make Payments") !== -1 && (
                  <NavItem
                    title="Make Payments"
                    link={URLs.Payments.path}
                    icon={faCreditCard}
                    className="text-center logo"
                  />
                )} */}
                {subscribed_products.indexOf("Job Scheduling") !== -1 && (
                  <CollapsableNavItem
                    eventKey="ajs/"
                    title="Job Scheduling"
                    icon={faIndustry}
                  >
                    <NavItem title="Orders" link={URLs.AJS.path} />
                    <NavItem
                      title="Assembly Status"
                      link={URLs.AJSAssemblyStatus.path}
                    />
                    {/* <NavItem
                  title="Assembly Demo"
                  link={Routes.AJSAssemblyDemo.path}
                /> */}
                    <NavItem title="Monitor" link={URLs.AJSMonitor.path} />
                    <NavItem
                      title="Masterdata"
                      link={URLs.AJSMasterData.path}
                    />
                  </CollapsableNavItem>
                )}
                {subscribed_products.indexOf("NetworkOps") !== -1 && (
                  <CollapsableNavItem
                    eventKey="network-optimization/"
                    title="Logistics Optimization"
                    icon={faGlobe}
                  >
                    <NavItem
                      title="Masterdata"
                      link={URLs.NetworkOptimizationMasterData.path}
                    />
                  </CollapsableNavItem>
                )}
                {subscribed_products.indexOf("Product Bundling") !== -1 && (
                  <NavItem
                    title="Product Bundling"
                    link={URLs.productBundling.path}
                    icon={faBoxesStacked}
                    className="text-center logo"
                  />
                )}
                {subscribed_products.indexOf("Make Payments") !== -1 && (
                  <CollapsableNavItem
                    //eventKey="exhibition/"
                    title="Make Payments"
                    icon={faCreditCard}
                  >
                    <NavItem
                      title="Invoices"
                      link={URLs.Payments.path}
                      // icon={}
                      className="text-center logo"
                    />

                    {subscribed_products.indexOf("Make Payments") !== -1 && (
                      <NavItem title="Suppliers" link={URLs.Suppliers.path} />
                    )}

                    {subscribed_products.indexOf("Make Payments") !== -1 && (
                      <NavItem title="Contacts" link={URLs.AddContacts.path} />
                    )}

                    {subscribed_products.indexOf("Make Payments") !== -1 && (
                      <NavItem
                        title="Fund Account"
                        link={URLs.FundAccount.path}
                      />
                    )}
                  </CollapsableNavItem>
                )}

                {subscribed_products.indexOf("Resource Allocation") !== -1 && (
                  <NavItem
                    title="Resource Allocation"
                    link={URLs.projectManagementURL.path}
                    icon={faUserGroup}
                    className="text-center logo"
                  />
                )}
                {subscribed_products.indexOf("O&M Inspection") !== -1 && (
                  <NavItem
                    title="Asset Inspection"
                    link={URLs.omInspectionURL.path}
                    icon={faMagnifyingGlass}
                    className="text-center logo"
                  />
                )}
                {subscribed_products.indexOf("Lab Management") !== -1 && (
                  <NavItem
                    title="Formulation, R&D Labs"
                    // link={"https://demo152.frappe.cloud/app/home"}
                    external={true}
                    icon={faPeopleArrows}
                    // target="_blank"
                    toOpenWithSession={true}
                    className="text-center logo"
                  />
                )}
                {subscribed_products.indexOf("ERP Implementation") !== -1 && (
                  <NavItem
                    title="ERP Website"
                    // link={"https://"}
                    external={true}
                    icon={faPeopleArrows}
                    // target="_blank"
                    toOpenWithSession={true}
                    className="text-center logo"
                  />
                )}
                {subscribed_products.indexOf("Lab Management") !== -1 && (
                  <NavItem
                    title="User Manual"
                    link={URLs.UserManual.path}
                    icon={faVialVirus}
                    className="text-center logo"
                  />
                )}
                {subscribed_products.indexOf("Mattermost") !== -1 && (
                  <NavItem
                    title="Mattermost"
                    link={process.env.REACT_APP_MATTERMOST_LINK}
                    external={true}
                    icon={faPeopleArrows}
                    target="_blank"
                    className="text-center logo"
                  />
                )}
                {subscribed_products.indexOf("Notes") !== -1 && (
                  <NavItem
                    title="Notes"
                    link={URLs.Notes.path}
                    icon={faBook}
                    className="text-center logo"
                  />
                )}
                {subscribed_products.indexOf("Timesheet") !== -1 && (
                  <NavItem
                    title="Timesheet"
                    link={URLs.TimesheetURL.path}
                    icon={faTimeline}
                    className="text-center logo"
                  />
                )}
                {subscribed_products.indexOf("Material Resource Planning") !== -1 && (
                  <NavItem
                    title="Material Resource Planning"
                    link={URLs.MRProute.path}
                    icon={faIndustry}
                    className="text-center logo"
                  />
                )}
                <Dropdown.Divider className="my-3 border-indigo" />
              </Nav>
            </div>
          </div>
          {/* <Link to={URLs.Logout.path} className="sidebar-text">
            <div className="logout-button">
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faSignOutAlt} />
              </span>
              {localStorage.getItem("user_email")}
            </div>
          </Link> */}
          <Box display="flex" alignItems="center" className="logout-button">
            <Avatar
              sx={{
                bgcolor: "#747687",
                width: 32,
                height: 32,
                fontSize: "1rem",
              }}
            >
              {getUserInitials()}
            </Avatar>
            <Typography variant="body1" sx={{ marginLeft: 2, marginRight: 0 }}>
              {userEmail.length > 13
                ? `${userEmail.slice(0, 13)}...`
                : userEmail}
            </Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <IconButton
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
              >
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                // elevation={100}
                anchorOrigin={{
                  vertical: "top", // Set anchor to the top
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom", // Set transform position to bottom
                  horizontal: "right",
                }}
              >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                <MenuItem onClick={handlePlansPricingOnclick}>
                  My Plans
                </MenuItem>
                <MenuItem onClick={handleCompanySettingsOnclick}>
                  Company Settings
                </MenuItem>
                <MenuItem onClick={handleConnectedAccountsOnclick}>
                  Connected Accounts
                </MenuItem>
                {appVersion !== latestVersion && (
                  <MenuItem onClick={handleUpdateButtonClick}>
                    Update to v{latestVersion}
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Sidebar;
