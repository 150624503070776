import React from 'react';
import {
    Box, Button, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Typography, useMediaQuery, IconButton
} from '@mui/material';
import useMrpStore from '../../stores/mrp/mrpStore';
import useFinalOverviewStore from '../../stores/mrp/finalOverviewStore'; // Import the Zustand store
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";

const FinalOverview = () => {
    const { setCurrentScreen } = useMrpStore();
    const isMobile = useMediaQuery('(max-width:768px)');

    // Use Zustand store
    const { finalToBeOrdered, expandedRows, toggleRow, handleOrderChange } = useFinalOverviewStore();

    return (
        <div>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                <Box display="flex" alignItems="center">
                    <FontAwesomeIcon
                        icon={faArrowLeftLong}
                        style={{ marginRight: 8, color: "#050092", height: 25, cursor: "pointer" }}
                        onClick={() => setCurrentScreen("Planning BOM")}
                    />
                    <Typography variant={isMobile ? "h6" : "h5"} style={{ fontFamily: "Rubik" }}>
                        Final Overview
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    onClick={() => {
                        console.log("Data to be submitted:", finalToBeOrdered); // Log the data
                        setCurrentScreen("Mrp Summary"); // Navigate to the next screen
                    }}
                    style={{
                        height: "40px",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#050092",
                        fontFamily: "Rubik"
                    }}
                >
                    {isMobile ? "Submit" : "Submit"}
                </Button>
            </Box>

            {/* Start Box container */}
            <Box p={2}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                    mb={3}
                    style={{
                        height: "70px",
                        border: "1px solid #E0E0E0",
                        borderRadius: "10px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                        paddingLeft: isMobile ? "15px" : "25px",
                        paddingRight: isMobile ? "15px" : "25px",
                    }}
                >
                    <Box textAlign="center">
                        <Typography style={{ color: "#424242", fontSize: isMobile ? "15px" : "19px", fontFamily: "Rubik" }}>
                            Total Items
                        </Typography>
                        <Typography style={{ color: "#424242", fontSize: "17px", fontFamily: "Rubik" }}>
                            {finalToBeOrdered.length}
                        </Typography>
                    </Box>

                    {/* Vertical Divider */}
                    <div style={{ width: "1px", height: "50px", backgroundColor: "#E0E0E0" }}></div>

                    <Box textAlign="center">
                        <Typography style={{ color: "#424242", fontSize: isMobile ? "15px" : "19px", fontFamily: "Rubik" }}>
                            Critical Items
                        </Typography>
                        <Typography color="error" style={{ fontSize: "17px", fontFamily: "Rubik" }}>
                            {finalToBeOrdered.filter(item => item.status === "Critical").length}
                        </Typography>
                    </Box>

                    {/* Vertical Divider */}
                    <div style={{ width: "1px", height: "50px", backgroundColor: "#E0E0E0" }}></div>

                    <Box textAlign="center">
                        <Typography style={{ color: "#424242", fontSize: isMobile ? "15px" : "18px", fontFamily: "Rubik" }}>
                            Latest Required By
                        </Typography>
                        <Typography style={{ color: "#424242", fontSize: isMobile ? "14px" : "16px", fontFamily: "Rubik" }}>
                            JUL 2025
                        </Typography>
                    </Box>
                </Box>

                {/* Closing Box before TableContainer starts */}
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontFamily:"Rubik"}}>ITEM</TableCell>
                            <TableCell align="center" style={{fontFamily:"Rubik"}}>TOTAL REQUIRED</TableCell>
                            <TableCell align="center" style={{fontFamily:"Rubik"}}>TOTAL PROJECTED</TableCell>
                            <TableCell align="center" style={{fontFamily:"Rubik"}}>TOTAL ORDER</TableCell>
                            <TableCell align="center" style={{fontFamily:"Rubik"}}>LEAD TIME</TableCell>
                            <TableCell align="center" style={{fontFamily:"Rubik"}}>STATUS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {finalToBeOrdered.map((row, index) => (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="subtitle1" fontWeight="bold" style={{ fontFamily: "Rubik" }}>
                                                {row.item}
                                            </Typography>
                                            <IconButton onClick={() => toggleRow(index)}>   
                                                <FontAwesomeIcon style={{ color: "black", fontSize: "15px" }} icon={expandedRows[index] ? faAngleDown : faAngleRight} />
                                            </IconButton>
                                        </Box>
                                        <Typography variant="body2" style={{ fontFamily: "Rubik" }}>{row.unit}</Typography>
                                    </TableCell>
                                    <TableCell align="center" style={{ fontFamily: "Rubik" }}>{row.totalRequired}</TableCell>
                                    <TableCell align="center" style={{ fontFamily: "Rubik" }}>{row.totalProjected}</TableCell>
                                    <TableCell align="center" style={{ fontFamily: "Rubik" }}>{row.totalOrder}</TableCell>
                                    <TableCell align="center" style={{ fontFamily: "Rubik" }}>{row.leadTime}</TableCell>
                                    <TableCell align="center">
                                        <Chip
                                            label={row.status}
                                            color={
                                                row.status === "Critical" ? "error" :
                                                    row.status === "Warning" ? "warning" : "success"
                                            }
                                            style={{fontFamily:"Rubik"}}
                                        />
                                    </TableCell>
                                </TableRow>
                                {expandedRows[index] && row.details.map((detail, detailIndex) => (
                                    <TableRow key={detailIndex}>
                                        <TableCell sx={{ pl: 4 }} style={{ fontFamily: "Rubik" }}>{detail.period}</TableCell>
                                        <TableCell align="center" style={{ fontFamily: "Rubik" }}>{detail.required}</TableCell>
                                        <TableCell align="center" style={{ fontFamily: "Rubik" }}>{detail.projected}</TableCell>
                                        <TableCell align="center" style={{ fontFamily: "Rubik" }}>
                                            <TextField
                                                value={detail.order}
                                                onChange={(e) => handleOrderChange(index, detailIndex, e.target.value)}
                                                type="number"
                                                size="small"
                                                variant="outlined"
                                                style={{ width: "80px" }}
                                            />
                                        </TableCell>
                                        <TableCell align="center" style={{ fontFamily: "Rubik" }}>{row.leadTime}</TableCell>
                                        <TableCell align="center">
                                            <Chip
                                                label={detail.status}
                                                color={
                                                    detail.status === "Critical" ? "error" :
                                                        detail.status === "Warning" ? "warning" : "success"
                                                }
                                                style={{fontFamily: "Rubik" }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default FinalOverview;