import {
  LocalMall,
  MenuBook,
  List as ListIcon,
  MoreVert,
  AccountBox,
  
} from "@mui/icons-material";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { URLs } from "../../routes";

const NavbarBP = () => {
  const pages = ["Contact"];
  const settings = ["Logout"];
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleChangeUserMenu = (data) => {
    if (data === "Logout") {
      navigate(URLs.Logout.path);
    }
  };

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "white", color: "#050092" , marginTop:"0px" , width:"100%", margin: "0 auto", // Centers the AppBar horizontally
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters  sx={{
          display: "flex",
          justifyContent: "center", // Centers content horizontally
          alignItems: "center", // Ensures vertical alignment
        }}>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 1,
              display: { xs: "none", md: "flex" },
              // fontFamily: "monospace",
              // fontWeight: 400,
              letterSpacing: "0.3rem",
              color: "#050092",
              textDecoration: "none",
              fontFamily:"Rubik",
              marginLeft:"65px"

            }}
          >
            <span style={{ }}>Mannlowe</span> :
            Digital Readiness Suite
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <ListIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "Rubik",
              // fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              fontSize:"21px",
            }}
          >
            <span>Mannlowe</span>: DRS
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  marginLeft: "5px",
                  color: "#050092",
                  backgroundColor: "white",
                  display: "block",
                }}
              >
                {/* {page} */}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* <AccountBox /> */}
                <SettingsSuggestIcon style={{fontSize:"30px" ,  color: "black",}}/>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleChangeUserMenu(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavbarBP;
