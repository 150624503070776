import {
    Box, Button, FormControl, MenuItem, Paper, Select,
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Typography, Checkbox, ListItemText, Chip, CircularProgress, IconButton,
    FormControlLabel,
    Switch,
    FormGroup,
    useMediaQuery
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import useMrpStore from '../../stores/mrp/mrpStore';
import usePlanningBomStore from '../../stores/mrp/planningBomStore';
import MultiLayerTable from './MultiLayerTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import trash from '../../assets/images/MRP Trash.png';

const PlanningBOM = () => {
    const { setCurrentScreen } = useMrpStore();
    const isMobile = useMediaQuery('(max-width:768px)');
    const [includeExpiredStock, setIncludeExpiredStock] = useState(false);

    const {
        plants, availableWarehouses, selectedPlants, selectedWarehouses,
        setSelectedPlants, setSelectedWarehouses, fetchData, isLoading, planningDetails, removePlanningDetail
    } = usePlanningBomStore();

    useEffect(() => {
        fetchData(); // Fetch Plants & Warehouses on mount
    }, []);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                <Box display="flex" alignItems="center">
                    <FontAwesomeIcon
                        icon={faArrowLeftLong}
                        style={{ marginRight: 8, color: "#050092", height: 25, cursor: "pointer" }}
                        onClick={() => setCurrentScreen("Product Selection")}
                    />

                </Box>
                <Button
                    variant="contained"
                    onClick={() => setCurrentScreen("Final Overview")}
                    style={{
                        height: "40px",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#050092",
                        fontFamily: "Rubik"
                    }}
                >
                    Proceed
                </Button>
            </Box>

            <Box mb={3} display="flex" flexWrap="wrap" gap={2}>
                {planningDetails.length > 0 ? (
                    planningDetails.map((product, index) => (
                        <Box
                            key={index}
                            p={2}
                            bgcolor="#f5f5f5"
                            borderRadius={2}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width={{ xs: "100%", sm: "48%" }} // Full width on small screens, 48% on larger screens
                            sx={{
                                background: "transparent",
                                border: "1px solid #f5f5f5",
                                borderRadius: "12px",
                                boxShadow: "3px 2px 2px rgba(0, 0, 0, 0.3)", // Dark gray shadow
                            }}
                        >
                            <Box>
                                <Typography variant="h6" style={{ fontFamily: "Rubik" }}>Planning for {product.selectedItem}</Typography>
                                <Typography variant="body1" style={{ fontFamily: "Rubik" }}>Planning Type: {product.planningType}</Typography>
                                {/* <Typography variant="body1" style={{ fontFamily: "Rubik" }}>Total Orders: {JSON.stringify(product.totalOrders)}</Typography> */}
                            </Box>

                            <IconButton onClick={() => removePlanningDetail(product.selectedItem)}>
                                <img src={trash} alt="Delete" style={{ width: 28, height: 28 }} />
                            </IconButton>

                        </Box>
                    ))
                ) : (
                    <Typography variant="body1" color="error" style={{ fontFamily: "Rubik" }}>No Data Available</Typography>
                )}
            </Box>




            <Box display="flex" justifyContent="space-between" mb={2}>
                {/* PLANT SELECTION */}
                <Box flex={1} mr={2}>
                    <Typography variant="subtitle1" style={{ fontFamily: "Rubik" }}>Select Plants</Typography>
                    <FormControl fullWidth>
                        <Select
                            multiple
                            value={selectedPlants}
                            onChange={(e) => setSelectedPlants(e.target.value)}
                            renderValue={(selected) => `${selected.length} plants selected`}
                            style={{ fontFamily: "Rubik", border: "1px solid gray", borderRadius: "14px" }}
                        >
                            {plants.map((plant) => (
                                <MenuItem key={plant.id} value={plant.id} >
                                    <Checkbox checked={selectedPlants.includes(plant.id)} />
                                    <ListItemText style={{ fontFamily: "Rubik" }} primary={plant.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                        {selectedPlants.map((plantId) => (
                            <Chip
                                key={plantId}
                                label={plants.find((p) => p.id === plantId)?.name}
                                onDelete={() => setSelectedPlants(selectedPlants.filter((id) => id !== plantId))}
                                style={{ fontFamily: "Rubik" }}
                            />
                        ))}
                    </Box>
                </Box>

                {/* WAREHOUSE SELECTION */}
                <Box flex={1} ml={2}>
                    <Typography variant="subtitle1" style={{ fontFamily: "Rubik" }}>Select Warehouses</Typography>
                    <FormControl fullWidth>
                        <Select
                            multiple
                            value={selectedWarehouses}
                            onChange={(e) => setSelectedWarehouses(e.target.value)}
                            renderValue={(selected) => `${selected.length} warehouses selected`}
                            style={{ fontFamily: "Rubik", border: "1px solid gray", borderRadius: "14px" }}
                        >
                            {availableWarehouses.map((warehouse) => (
                                <MenuItem key={warehouse.id} value={warehouse.id}>
                                    <Checkbox checked={selectedWarehouses.includes(warehouse.id)} />
                                    <ListItemText primary={warehouse.name} style={{ fontFamily: "Rubik" }} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                        {selectedWarehouses.map((warehouseId) => (
                            <Chip
                                key={warehouseId}
                                label={availableWarehouses.find((w) => w.id === warehouseId)?.name}
                                onDelete={() => setSelectedWarehouses(selectedWarehouses.filter((id) => id !== warehouseId))}
                                style={{ fontFamily: "Rubik" }}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
            <FormGroup>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Switch
                            checked={includeExpiredStock}
                            onChange={() => setIncludeExpiredStock(!includeExpiredStock)}
                        />
                    <Typography sx={{ fontFamily: 'Rubik, sans-serif' }}>
                        Include Expired Stock
                    </Typography>
                </div>
            </FormGroup>

            <MultiLayerTable includeExpiredStock={includeExpiredStock} />
        </Box>
    );
};

export default PlanningBOM;
