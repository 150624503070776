import { AppBar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, Toolbar, useMediaQuery } from '@mui/material'
import React from 'react'
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GetAppIcon from "@mui/icons-material/GetApp";
import SyncIcon from "@mui/icons-material/Sync";
import ProductSelection from './ProductSelection';
import PlanningBOM from './PlanningBOM';
import FinalOverview from './FinalOverview';
import MrpSummary from './MrpSummary';
import useMrpStore from '../../stores/mrp/mrpStore';

const MrpHome = () => {
  const isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  const { currentScreen } = useMrpStore();

  return (
    <Card
      className="shadow-sm"
      variant="outlined"
      sx={{
        border: "1px solid lightgray",
        borderRadius: "10px",
        width: "100%",
      }}
    >
      <CardHeader
        title="Material Resource Planning"
        action={
          <div style={{ marginTop: isMobileOrTablet ? "5px" : "0px" }}>
            <input
              type="file"
              accept=".json"
              // onChange={handleFileUpload}
              style={{ display: "none" }}
              id="fileInput" // Give it an id for labeling
            />
            <label htmlFor="fileInput">
              <Button
                variant="outlined"
                component="span"
                style={{
                  marginRight: "8px",
                  borderColor: "#050092",
                  color: "#050092",
                }}>
                <CloudUploadIcon />
              </Button>
            </label>
            <Button
              variant="outlined"
              style={{
                marginRight: "8px",
                borderColor: "#050092",
                color: "#050092",
              }}
            // onClick={handleExportToJson}
            // startIcon={<GetAppIcon />}
            >
              <GetAppIcon />
            </Button>{" "}
            <Button
              variant="contained"
              style={{
                marginRight: "8px",
                backgroundColor: "#050092",
                color: "#fff",
                fontFamily:"Rubik"
              }}
            // onClick={handleDataSync}
            // startIcon={
            //   isDataSyncing ? (
            //     <CircularProgress size={20} color="inherit" />
            //   ) : (
            //     <SyncIcon />
            //   )
            // }
            >
              Sync
            </Button>
          </div>
        }
        sx={{
          margin: "15px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobileOrTablet ? "column" : "row",
    "& .MuiCardHeader-title": {
      fontFamily: "Rubik, sans-serif",
    },
        }}
      />
      <Divider sx={{ backgroundColor: "darkgray" }} />
      <CardContent style={{ backgroundColor: "#ffffff", minHeight: "80vh" }}>
      
        <Box p={2}>
            {/* Initial Screen */}
            {currentScreen === "Product Selection" && (<ProductSelection />)}
            {/* Planning Screen */}
            {currentScreen === "Planning BOM" && (<PlanningBOM />)}
            {/* Final Overview Screen */}
            {currentScreen === "Final Overview" && (<FinalOverview />)}
            {/* Summary Screen */}
            {currentScreen === "Mrp Summary" && (<MrpSummary />)}
        </Box>
      </CardContent>
    </Card>
  )
}

export default MrpHome