import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { URLs } from "../../routes";
import { axiosInstance } from "../../axiosSetup";
import {
  GetSubscribedPlans,
  getSubscribedPlansAPIerp,
} from "../../api/BuyPlansAPI";
import { useEffect } from "react";
import { FormatDate } from "../../components/dateformat";
import { AccountBox, DateRange, LocalMall, Refresh } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";


const MyPlans = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [subscriptionList, setSubscriptionList] = useState(null);
  const isExtraSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");

  useEffect(() => {
    getSubscribedPlansData();
  }, []);

  const getSubscribedPlansData = async () => {
    try {
      const res = await getSubscribedPlansAPIerp();
      // console.log(res);

      setSubscriptionList(res.data.subscribed_plan_list);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="page-container">
      <Card
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid lightgray",
          borderRadius: "10px",
          width: "100%",
          minHeight: "90vh",
          overflowX: "hidden",
        }}
      >
        <CardHeader
          title="My Subscriptions"
          sx={{
            margin: "15px",
            // display: "flex",
            // justifyContent: "space-between",
          }}
          action={
            <div
              style={{
                display: "flex",
                flexDirection: isExtraSmallScreen
                  ? "column"
                  : isMediumScreen
                    ? "row"
                    : "row",
              }}
            >
              <Button
                variant="outlined"
                style={{
                  marginRight: "8px",

                  // backgroundColor: "#fff",
                  color: "#050092",
                }}
                onClick={() => getSubscribedPlansData()}
              >
                <Refresh /> Refetch
              </Button>
              <Button
                variant="contained"
                style={{
                  marginTop: isExtraSmallScreen ? "3px" : "0px",
                  backgroundColor: "#050092",
                  color: "#fff",
                }}
                onClick={() => navigate(URLs.BuyPlanURL.path)}
              >
                <LocalMall /> Buy Plans
              </Button>
            </div>
          }
        />
        <Divider sx={{ backgroundColor: "darkgray" }} />
        {subscriptionList !== null && (
          <Grid container spacing={5} sx={{ margin: "15px" }}>
            {subscriptionList.map((sub, index) => (
              <Grid item lg={11} md={12} sm={12} xs={12} key={index}>
                <Card
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    boxShadow: "0 10px 10px rgba(0, 0, 0, 0.3)",
                    // padding: "20px",
                    // margin: "0 20px 20px 20px",
                    // marginBottom: isExtraSmallScreen ? "15px" : "0",
                  }}
                >
                  <CardContent
                    sx={{ borderBottom: "1px solid #050092", color: "#050092" }}
                  >
                    <Grid container spacing={2}>
                      <Grid item lg={8} md={12} sm={12} xs={12}>
                        {/* <Badge badgeContent={sub.status} color="primary"> */}
                        {sub.plans.length === 1 && (
                          <h3 style={{ color: "#050092", marginTop: "6px" }}>
                            {index + 1}. {sub.plans.map((u) => u.plan)}{" "}
                          </h3>
                        )}
                        {sub.plans.length > 1 && (
                          <h4 style={{ color: "#050092", marginTop: "6px"}}>
                            {index + 1}. Products Bundle
                          </h4>
                        )}
                        {/* </Badge> */}
                      </Grid>
                      <Grid item lg={4} md={12} sm={12} xs={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Chip
                            size="small"
                            color="success"
                            variant="outlined"
                            label={sub.status}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "2px",
                          }}
                        >
                          <Chip label={`ID: ${sub.name}`} variant="outlined" />
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item lg={8} md={12} sm={12} xs={12}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          {/* <div>
                          plans -{sub.plans.map((u) => u.plan).join(", ")}
                        </div> */}
                          {sub.start_date !== null && sub.end_date !== null && (
                            <Box
                              style={{
                                // backgroundColor: "#FFF4F7",
                                border: "1px solid #050092",
                                padding: "10px",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  lg={5}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    backgroundColor: "#C5FCEF",
                                    color: "#050092",
                                  }}
                                >
                                  {" "}
                                  <div>Invoice Start</div>
                                  <h4 style={{ color: "#050092" }}>
                                    {sub.start_date}
                                  </h4>
                                </Grid>
                                <Grid
                                  item
                                  lg={2}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  {" "}
                                  <div>
                                    <DateRange
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        color: "#050092",
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  lg={5}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    backgroundColor: "#C5FCEF",
                                    color: "#050092",
                                  }}
                                >
                                  <div>Invoice End</div>
                                  <h4 style={{ color: "#050092" }}>
                                    {sub.end_date}
                                  </h4>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                          {sub.start_date === null && sub.end_date === null && (
                            <Box
                              style={{
                                border: "1px solid #050092",
                                padding: "10px",
                                borderRadius: "4px",
                                overflow: "hidden", // Ensures content doesn't overflow
                              }}
                            >
                              <Grid container spacing={0}>
                                <Grid
                                  item
                                  lg={5}
                                  md={5}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    backgroundColor: "white",
                                    color: "#050092",
                                    padding: "10px",
                                    border: "1px solid",
                                    borderRadius: "12px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography variant="subtitle2">Start Date</Typography>
                                  <Typography variant="h6">{sub.current_invoice_start}</Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={2}
                                  md={2}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    padding: "10px",
                                  }}
                                >
                                  <DateRange
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      color: "#050092",
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  lg={5}
                                  md={5}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    backgroundColor: "white",
                                    color: "#050092",
                                    padding: "10px",
                                    border: "1px solid",
                                    borderRadius: "12px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography variant="subtitle2">End Date</Typography>
                                  <Typography variant="h6">{sub.current_invoice_end}</Typography>
                                </Grid>
                              </Grid>
                            </Box>

                          )}
                        </Grid>
                        <div style={{ marginTop: "20px" }}></div>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          {sub.plans.length === 1 &&
                            (sub.plans[0].plan === "SDS" ||
                              sub.plans[0].plan === "Visiting Cards" ||
                              sub.plans[0].plan === "SDS Trial" ||
                              sub.plans[0].plan === "Visiting Cards Trial") && (
                              <Box
                                style={{
                                  // backgroundColor: "#e5f6fd",
                                  border: "1px solid #050092",
                                  padding: "10px",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    lg={4}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      backgroundColor: "#C5FCEF",
                                      color: "#050092",
                                    }}
                                  >
                                    {" "}
                                    <div>Total Credits</div>
                                    <h4 style={{ color: "#050092" }}>
                                      {sub.plans[0].custom_credits}
                                    </h4>
                                  </Grid>

                                  <Grid
                                    item
                                    lg={4}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      backgroundColor: "#00B7E7",
                                      color: "#050092",
                                    }}
                                  >
                                    <div>Used</div>
                                    {sub.plans[0].qty >= 0 &&
                                      sub.plans[0].qty <=
                                      sub.plans[0].custom_credits && (
                                        <h4 style={{ color: "#050092" }}>
                                          {sub.plans[0].custom_credits -
                                            sub.plans[0].qty}
                                        </h4>
                                      )}

                                    {sub.plans[0].qty < 0 &&
                                      sub.plans[0].qty <=
                                      sub.plans[0].custom_credits && (
                                        <h4 style={{ color: "#050092" }}>
                                          {sub.plans[0].custom_credits +
                                            Math.abs(sub.plans[0].qty)}
                                        </h4>
                                      )}
                                  </Grid>
                                  {sub.plans[0].qty >= 0 &&
                                    sub.plans[0].qty <=
                                    sub.plans[0].custom_credits && (
                                      <Grid
                                        item
                                        lg={4}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          flexDirection: "column",
                                          backgroundColor: "#C5FCEF",
                                          color: "#050092",
                                        }}
                                      >
                                        {" "}
                                        <div>Remaining</div>
                                        <h4 style={{ color: "#050092" }}>
                                          {sub.plans[0].qty}
                                        </h4>
                                      </Grid>
                                    )}
                                </Grid>
                              </Box>
                            )}
                          {sub.plans.length > 1 && (
                            <Box
                              style={{
                                // backgroundColor: "#e5f6fd",
                                // border: "1px solid #F05E85",
                                padding: "10px",
                              }}
                            >
                              <Grid container spacing={2}>
                                {sub.plans.map((p, index) => {
                                  return (
                                    <Grid
                                      key={index + 1}
                                      item
                                      lg={4}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Alert
                                        icon={
                                          <FontAwesomeIcon
                                            icon={faCircleCheck}
                                            style={{ color: "#388e3c", backgroundColor:"white" }} // Red tick mark
                                          />
                                        }
                                        style={{
                                          backgroundColor: "white",
                                          border: "1px solid #050092",
                                        }}
                                      >
                                        {p.plan}
                                      </Alert>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item lg={4} md={12} sm={12} xs={12}>
                        <div
                          style={{
                            borderLeft: "2px solid grey",
                            padding: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "bold",
                              textDecoration: "underline",
                            }}
                          >
                            Users
                          </div>
                          {sub.custom_subscription_users.length > 0 && (
                            <List dense={true}>
                              {sub.custom_subscription_users.map((u) => {
                                return (
                                  <ListItem key={u.username}>
                                    <ListItemIcon>
                                      <AccountBox />
                                    </ListItemIcon>
                                    <ListItemText primary={u.username} />
                                  </ListItem>
                                );
                              })}
                            </List>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Card>
    </div>
  );
};

export default MyPlans;
