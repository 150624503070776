import React, { useState } from 'react';
import { Modal, Box, Typography, Button, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import useMrpStore from '../../stores/mrp/mrpStore';
import mrpSuccess from '../../assets/images/MRP Success.png';

const CenteredModal = () => {
    const [open, setOpen] = useState(true);
    const { setCurrentScreen } = useMrpStore(); // Getting navigation function
    const isMobile = useMediaQuery('(max-width:768px)');

    const handleButtonClick = () => {
        setOpen(false);
        setCurrentScreen("Product Selection"); // Navigate back
    };

    return (
        <Modal
            open={open}
            onClose={() => {}} // Prevent modal from closing on outside click
            BackdropProps={{
                onClick: (event) => event.stopPropagation(), // Prevent backdrop clicks from closing modal
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: isMobile ? "50%" : '55%',
                    transform: 'translate(-50%, -50%)',
                    width: isMobile ? 350 : 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 4,
                    textAlign: 'center',
                }}
            >
                {/* MRP Success Image */}
                <img
                    src={mrpSuccess}
                    alt="MRP Success"
                    style={{ width: 100, marginBottom: 16 }}
                />

                <Typography gutterBottom style={{ fontSize: '24px', color: 'black', fontFamily: 'Rubik' }}>
                    Material request submitted successfully!
                </Typography>

                {/* Back to Home Button */}
                <Button
                    variant="text"
                    onClick={handleButtonClick}
                    sx={{ display: 'flex', alignItems: 'center', mt: 2, color: '#050092', ml: isMobile ? 8 : 11 }}
                    style={{ fontFamily: "Rubik" }}
                >
                    <FontAwesomeIcon
                        icon={faHouse}
                        style={{ marginRight: 8, height: 15, cursor: 'pointer' , marginTop:"-2px" }}
                    />
                    Go to M.R.P Home
                </Button>
            </Box>
        </Modal>
    );
};

export default CenteredModal;
