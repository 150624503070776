import {
  Close,
  Delete,
  Edit,
  GetApp,
  PublishedWithChanges,
  SettingsSuggest,
  Upgrade,
  Upload,
} from "@mui/icons-material";
import {
  Box,
  Button as ButtonMui,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  InputLabel,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Alert as AlertMui,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  UpdateLogoAPI,
  getCompantDetailsAPIerp,
  patchCompantDetailsAPIerp,
} from "../../api/CompanyDetails";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../axiosSetup";

const CompanySettings = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [showModal, setshowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [updatedCompanyDetails, setUpdatedCompanyDetails] = useState(null);
  const [snakbarST, setSnakbarST] = useState({
    openSnakbar: false,
    snackbarMessage: "",
    severity: "info",
  });
  useEffect(() => {
    getCompantDetails();
  }, []);
  const getCompantDetails = async () => {
    try {
      const res = await getCompantDetailsAPIerp();
      // console.log(res);
      if (res.status == 200) {
        setCompanyDetails(res.data.company_details);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const companyID =
    localStorage.getItem("group") && localStorage.getItem("group").length > 0
      ? JSON.parse(localStorage.getItem("group"))[
          JSON.parse(localStorage.getItem("group")).length - 1
        ].id
      : null;

  const handleClose = () => setshowModal(false);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    // Check if a file is selected
    if (selectedFile) {
      // Check if the selected file format is allowed
      const allowedFormats = [".jpg", ".jpeg", ".png", ".svg", ".ico"];
      const fileFormat = selectedFile.name.slice(
        ((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2
      );

      if (!allowedFormats.includes(`.${fileFormat.toLowerCase()}`)) {
        // Show an error alert if the format is not allowed
        setAlertMsg("Invalid file format. Please choose a valid image file.");
        setAlertVariant("danger");
        setShowAlert(true);
        // Clear the file input
        e.target.value = null;
        // Clear the image state
        setImage(null);
        setPreview(null);
        return;
      }

      if (selectedFile) {
        // console.log(img.type);
        if (selectedFile.type.includes("image")) {
          setPreview(URL.createObjectURL(selectedFile));
        }
        setImage(selectedFile);
      } else {
        setPreview(null);
        setImage(null);
      }
    }
  };
  const handleChange2 = (e) => {
    setUpdatedCompanyDetails({
      ...updatedCompanyDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleCloseSnakbar = () => {
    setSnakbarST({
      ...snakbarST,
      openSnakbar: false,
      // snackbarMessage: "",
      // severity: "info",
    });
  };
  const apiCall = async () => {
    try {
      const res = await patchCompantDetailsAPIerp(updatedCompanyDetails, image);
      console.log(res);
      if (res.status == 200) {
        setCompanyDetails(res.data.company_details);
        setShowSpinner(false);
        setshowModal(false);
        setSnakbarST({
          ...snakbarST,
          openSnakbar: true,
          snackbarMessage: `Updated Successfully`,
          severity: "success",
        });
        if (res.data.company_details.company_logo) {
          localStorage.setItem(
            "company_logo",
            res.data.company_details.company_logo
          );
        }
      }
    } catch (error) {
      console.log(error);
      setShowSpinner(false);
      setShowAlert(true);
      setAlertVariant("danger");
      setAlertMsg("Upload Failed!");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    // console.log(image);
    // console.log(updatedCompanyDetails);
    apiCall();
  };

  return (
    <div className="page-container">
      {companyDetails && (
        <Card
          className="shadow-sm"
          variant="outlined"
          sx={{
            border: "1px solid lightgray",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <CardHeader
            title="Company Settings"
            action={
              <div style={{ marginTop: isMobileOrTablet ? "5px" : "0px" }}>
                {/* <Button
                onClick={() => {
                  setshowModal(true);
                }}
                variant="outlined"
                style={{
                  marginRight: "8px",
                  borderColor: "#050092",
                  color: "#050092",
                }}
              >
                <SettingsSuggest />
              </Button>{" "} */}
              </div>
            }
            sx={{
              margin: "15px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isMobileOrTablet ? "column" : "row",
            }}
          />

          <Divider sx={{ backgroundColor: "darkgray" }} />
          <CardContent
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Card
              sx={{
                maxWidth: "50vw",
                maxHeight: "70vh",
                border: "1px solid #bdbdbd", // Change black to light gray
                borderRadius: "18px",
                boxShadow: "10px 10px 15px rgba(255, 255, 255, 0.5)", // White shadow
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  
                }}
              >
                {companyDetails.company_logo !== null ? (
                  <img
                    src={
                      localStorage.getItem("base_url") +
                      companyDetails.company_logo
                    }
                    style={{
                      maxWidth: "50%",
                      maxHeight: "50%",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <div></div>
                )}
              </div>

              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography gutterBottom variant="h6" component="div" style={{fontFamily:"Montserrat"}}>
                  {companyDetails.company_name}
                </Typography>
                <Typography color="text.secondary" style={{fontFamily:"Montserrat", fontSize:"14px"}}>
                  Contact Number: {companyDetails.phone_no}
                </Typography>
              </CardContent>
              <CardActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonMui
                  onClick={() => {
                    setshowModal(true);
                    setUpdatedCompanyDetails({
                      phone_no: companyDetails.phone_no,
                    });
                  }}
                  variant="contained"
                  sx={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    minWidth: "unset",
                    padding: "10px",
                  }}
                >
                  <Edit />
                </ButtonMui>
              </CardActions>
            </Card>
          </CardContent>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={snakbarST.openSnakbar}
              autoHideDuration={3000}
              onClose={handleCloseSnakbar}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <AlertMui
                onClose={handleCloseSnakbar}
                severity={snakbarST.severity}
              >
                {snakbarST.snackbarMessage}
              </AlertMui>
            </Snackbar>
          </Stack>
        </Card>
      )}
      {showModal && (
        <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="h6">Edit</Modal.Title>
              <Button
                variant="close"
                aria-label="Close"
                onClick={handleClose}
              />
            </Modal.Header>
            <Modal.Body>
              {showSpinner && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="grow" variant="info" size="md" />
                </div>
              )}

              <React.Fragment>
                {showAlert && (
                  <Alert
                    variant={alertVariant}
                    onClose={() => setShowAlert(false)}
                    dismissible
                  >
                    {alertMsg}
                  </Alert>
                )}
              </React.Fragment>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Mobile No.</Form.Label>
                <Form.Control
                  name="phone_no"
                  type="number"
                  placeholder="mobile no."
                  onChange={handleChange2}
                  value={updatedCompanyDetails.phone_no}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Choose Logo</Form.Label>
                <Form.Control
                  type="file"
                  accept=".jpg,.jpeg,.png,.svg,.ico"
                  onChange={handleChange}
                  name="company_logo"
                  // value={companyDetails.company_logo}
                />
              </Form.Group>

              <React.Fragment>
                {image && preview && (
                  <div>
                    <br />
                    <img src={preview} className="card-img-top" alt="preview" />
                    <br />
                  </div>
                )}
              </React.Fragment>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="secondary" className="ms-auto" type="submit">
                Upload
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default CompanySettings;
