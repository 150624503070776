import React from "react";
import NavbarBP from "./NavbarBP";
import ProductListBP from "./ProductListBP";
import FooterBP from "./FooterBP";

const BuyPlans = () => {
  return (
    <div
    style={{
      backgroundColor: "#5899e2",
      backgroundImage: "linear-gradient(315deg, #ffffff 20%, #5899e2 100%)",
      minHeight: "100vh", // Ensures the gradient covers the full viewport height
    }}
  >
    <NavbarBP />
    <ProductListBP />
    <FooterBP />
  </div>
  
  );
};

export default BuyPlans;
