import React, { useState } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import StripedDataGridBase from "../calculator/components/StripedDataGridBase";
import { useRef } from "react";
import {
  CharComponent,
  DateComponent,
  DatetimeComponent,
  StatusComponent,
} from "./GridCellComponents";
import { useEffect } from "react";
import "./Exhibition.css";
import { useNavigate } from "react-router-dom";
import { LinearProgress, useMediaQuery } from "@mui/material";

const getSelectedRowsToExport = ({ apiRef }) => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }

  return gridFilteredSortedRowIdsSelector(apiRef);
};

const CustomGridToolbar = () => {
  return (
    <GridToolbarContainer style={{ justifyContent: "flex-end" }}>
      {/* Add only the toolbar options you want to keep */}
      {/* <GridToolbarColumnsButton /> */}
      <GridToolbarDensitySelector />
      <GridToolbarExport />

      {/* You can add more toolbar options here if needed */}
    </GridToolbarContainer>
  );
};

export default function VisitingCardsGrid(props) {
  const { pageNum, rowData, handleRowsSelection } = props;
  // console.log(data);
  const metadata = JSON.parse(localStorage.getItem("metadata"))?.visiting_cards;
  const columnHeaders = metadata.home.filter((item) => item.order);
  columnHeaders.sort(function (a, b) {
    return a.order - b.order;
  });
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const fieldNames = [];
  const initialColumns = [
    // { field: "id", headerName: "#", width: 10, align: "left" },
    ...columnHeaders?.map((header, index) => {
      const type = header.type;
      const wheretoLook = header.loc?.length === 1 ? "Object" : header.loc[0];
      const fieldName = header.loc[header.loc.length - 1];
      fieldNames.push({ wheretoLook, fieldName });
      const commonProperties = {
        field: fieldName,
        loc: header.loc,
        type: type,
        headerName: header.name.toUpperCase(),
        flex: 1,
        align: "left",
        headerAlign: "left",
      };
      let additionalProperties = {};
      if (type === "status") {
        additionalProperties.renderCell = (params) => (
          <StatusComponent
            id={params.id}
            value={params.value}
            loc={params.loc}
            field={params.field}
            row={params.row}
          />
        );
      } else if (type === "date") {
        additionalProperties.renderCell = (params) => (
          <DateComponent
            id={params.id}
            value={params.value}
            loc={params.loc}
            field={params.field}
            row={params.row}
          />
        );
      } else if (type === "char") {
        additionalProperties.renderCell = (params) => (
          <CharComponent
            id={params.id}
            value={params.value}
            loc={params.loc}
            field={params.field}
            row={params.row}
          />
        );
      } else if (type === "datetime") {
        additionalProperties.renderCell = (params) => (
          <DatetimeComponent
            id={params.id}
            value={params.value}
            loc={params.loc}
            field={params.field}
            row={params.row}
          />
        );
        // Add more properties specific to 'number' type if needed
      } else {
        additionalProperties.renderCell = (params) => (
          <CharComponent
            id={params.id}
            value={params.value}
            field={params.field}
            row={params.row}
          />
        );
      }
      if (fieldName === "WorkPhone") {
        additionalProperties.valueFormatter = (params) => {
          // Ensure that the contact number is exported as a string in quotes
          return `'${params.value}`;
        };
      }
      return {
        ...commonProperties,
        ...additionalProperties,
      };
      // renderEditCell: NumericEditComponentRM, // Use the custom edit component for numerical values
    }),
  ];
  const [columns, setColumns] = useState(initialColumns);
  const [rows, setRows] = useState(null);
  useEffect(() => {
    const tempRowData = [];
    rowData.forEach((row, index) => {
      const item = {};
      item["db_id"] = row.id;
      item["id"] = (pageNum - 1) * 10 + index + 1;
      fieldNames.forEach((fieldItem) => {
        if (fieldItem.wheretoLook === "Object") {
          item[fieldItem.fieldName] = row[fieldItem.fieldName];
        } else {
          // console.log(row[fieldItem.wheretoLook])
          item[fieldItem.fieldName] = row[fieldItem.wheretoLook]
            ? row[fieldItem.wheretoLook][fieldItem.fieldName]
            : "";
        }
      });
      tempRowData.push(item);
    });
    setRows(tempRowData);
  }, [rowData]);

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = containerRef.current.offsetWidth;
      const totalWidth = containerWidth + 0.055 * containerWidth;
      //   : 20; // lg and xl:
      let sizeWidth;
      if (window.innerWidth <= 400) {
        // for screens smaller or equal to 600px (xs)
        sizeWidth = 30; // 35% width
      } else if (window.innerWidth <= 825) {
        // for screens smaller or equal to 960px (sm to md)
        sizeWidth = 20; // 25% width
      } else {
        // for larger screens (lg and xl)
        sizeWidth = 13; // 20% width
      }
      // console.log(sizeWidth);
      const newColumns = columns.map((col, index) => {
        // Calculate percentage width based on the total width
        let width = sizeWidth; // Default widths
        // if (col.type === "char") width = 12;
        return {
          ...col,
          width: (totalWidth * width) / 100,
        };
      });
      console.log(newColumns)
      // Set the columns with the updated width values
      setColumns(newColumns);
    };

    // Call the handleResize function initially and add it to the resize event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRowClick = (params) => {
    const dbId = params?.row?.db_id;
    if (dbId) {
      navigate(`/exhibition/visiting-cards/${params.row.db_id}`);
    }
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    // console.log(newSelectionModel);
    const selectedRows = [];
    newSelectionModel.forEach((selectedRowID) => {
      const row = rows.find((row) => row.id === selectedRowID);
      selectedRows.push({
        id: row.db_id,
        contact_status: row.contact_status,
        contact_type: row.contact_type,
      });
    });
    setRowSelectionModel(newSelectionModel);
    handleRowsSelection(selectedRows);
  };

  return (
    <div style={{ height: "100%", width: "100%" }} ref={containerRef}>
      {rows && (
        <StripedDataGridBase
          rows={rows}
          columns={columns}
          // getRowHeight={() => 'auto'} 
          onRowClick={handleRowClick}
          density={
            isExtraSmallScreen
              ? "compact"
              : isMediumScreen
              ? "standard"
              : "comfortable"
          }
          autoHeight
          slots={{ toolbar: CustomGridToolbar }}
          slotProps={{
            toolbar: {
              printOptions: { getRowsToExport: getSelectedRowsToExport },
            },
          }}
          // rowHeight={32}
          // headerHeight={48}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
          // hideFooter={true}
          hideFooterPagination
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          sx={{
            // backgroundColor: "#F5F8FB",
            ".MuiDataGrid-columnHeader": {
              backgroundColor: "#F5F8FB",
            },
          }}
          // getRowHeight={function (params) {
          //   return 50;
          // }}
        />
      )}
    </div>
  );
}
