import { create } from "zustand";

// Mock API Calls (Replace with actual API calls)
const fetchProductData = async () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                products: [
                    { id: "FG002", name: "Dining Table (PCS)" },
                    { id: "FG003", name: "Chair (PCS)" },
                ],
                planningTypes: ["Daily", "Weekly", "Monthly", "Quarterly"],
            });
        }, 1000);
    });
};

const fetchSalesOrders = async (productId, planningType) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const salesData = {
                FG002: {
                    Daily: { "Feb 10": 5, "Feb 11": 3, "Feb 12": 7, "Feb 13": 10, "Feb 14": 4, "Feb 15": 6 },
                    Weekly: { "Week 1": 30, "Week 2": 25, "Week 3": 40, "Week 4": 50, "Week 5": 60, "Week 6": 45 },
                    Monthly: { "Jan 2025": 80, "Feb 2025": 90, "Mar 2025": 70, "Apr 2025": 85, "May 2025": 95, "Jun 2025": 110 },
                    Quarterly: { "Q1 2025": 250, "Q2 2025": 260, "Q3 2025": 275, "Q4 2025": 280 },
                },
                FG003: {
                    Daily: { "Feb 10": 2, "Feb 11": 4, "Feb 12": 6, "Feb 12": 8, "Feb 14": 10, "Feb 15": 12 },
                    Weekly: { "Week 1": 20, "Week 2": 22, "Week 3": 25, "Week 4": 28, "Week 5": 30, "Week 6": 35 },
                    Monthly: { "Jan 2025": 50, "Feb 2025": 55, "Mar 2025": 60, "Apr 2025": 65, "May 2025": 70, "Jun 2025": 75 },
                    Quarterly: { "Q1 2025": 120, "Q2 2025": 130, "Q3 2025": 140, "Q4 2025": 150 },
                },
            };
            resolve(salesData[productId]?.[planningType] || {});
        }, 1000);
    });
};

// Helper function to generate columns dynamically
const generateColumns = (planningType) => {
    const today = new Date();
    const columns = [];

    for (let i = 0; i < 6; i++) {
        let date = new Date(today);
        if (planningType === "Daily") {
            date.setDate(today.getDate() + i);
            columns.push(date.toLocaleDateString("en-US", { month: "short", day: "numeric" }));
        } else if (planningType === "Weekly") {
            date.setDate(today.getDate() + i * 7);
            columns.push(`Week ${i + 1}`);
        } else if (planningType === "Monthly") {
            date.setMonth(today.getMonth() + i);
            columns.push(date.toLocaleDateString("en-US", { month: "short", year: "numeric" }));
        } else if (planningType === "Quarterly") {
            date.setMonth(today.getMonth() + i * 3);
            columns.push(`Q${i + 1} ${date.getFullYear()}`);
        }
    }
    return columns;
};

// Define initial state
const initialState = {
    selectedItem: "",
    planningType: "Monthly",
    demandData: {},
    salesOrders: {},
    totalOrders: {},
    products: [],
    planningTypes: [],
    columns: generateColumns("Monthly"),
    isLoadingProducts: true,
    isLoadingSales: false,
};

const useProductSelectionStore = create((set, get) => ({
    ...initialState,

    fetchData: async () => {
        const data = await fetchProductData();
        set({
            products: data.products,
            planningTypes: data.planningTypes,
            isLoadingProducts: false,
        });
    },

    fetchSalesOrders: async () => {
        const { selectedItem, planningType } = get();
        if (!selectedItem) return;

        set({ isLoadingSales: true });

        const productId = selectedItem.split(" - ")[0];
        const salesData = await fetchSalesOrders(productId, planningType);

        set({
            salesOrders: salesData,
            isLoadingSales: false,
        });

        get().calculateTotalOrders();
    },

    setSelectedItem: (selectedItem) => {
        set({
            selectedItem,
            demandData: {}, // Reset demand data when switching products
        });
        get().fetchSalesOrders();
    },


    setPlanningType: (planningType) => {
        const { selectedItem, demandData } = get();
        set({
            planningType,
            columns: generateColumns(planningType),
            demandData: { ...demandData }, // Retain existing demand data per product
        });
        get().fetchSalesOrders();
    },


    setDemandData: (newDemandData) => {
        set({ demandData: newDemandData });
        get().calculateTotalOrders();
    },

    calculateTotalOrders: () => {
        const { demandData, salesOrders, columns } = get();
        const totalOrders = {};

        columns.forEach((col) => {
            totalOrders[col] = (demandData[col] || 0) + (salesOrders[col] || 0);
        });

        set({ totalOrders });
    },

    resetProductSelectionStore: () => set({ ...initialState }),
}));

export default useProductSelectionStore;
