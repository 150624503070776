import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Typography,
    Card,
    Box,
    Checkbox,
    Button
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { motion } from "framer-motion";

const mockData = {
    "Dining Table": {
        parts: [
            {
                id: "P001",
                name: "Table Top",
                subParts: [
                    { id: "S001", name: "Wood Panel", expired: false },
                    { id: "S002", name: "Wood Finish", expired: true } // Marking this as expired
                ]
            },
            {
                id: "P002",
                name: "Table Leg",
                subParts: [
                    { id: "S003", name: "Wood Block", expired: false },
                    { id: "S004", name: "Bush", expired: false }
                ]
            }
        ]
    },
    "Office Desk": {
        parts: [
            {
                id: "P003",
                name: "Desk Top",
                subParts: [
                    { id: "S005", name: "Wood Panel", expired: true } // Expired subpart
                ]
            },
            {
                id: "P004",
                name: "Desk Leg",
                subParts: [
                    { id: "S006", name: "Metal Tube", expired: false }
                ]
            }
        ]
    }
};


const months = ["Feb 2025", "Mar 2025", "Apr 2025", "May 2025"];

const MultiLayerTable =  ({ includeExpiredStock }) => {
    const [expandedRows, setExpandedRows] = useState({});
    const [expandedSummaries, setExpandedSummaries] = useState({});
    const [selectedSubParts, setSelectedSubParts] = useState([]);

    const toggleRow = (key) => {
        setExpandedRows((prev) => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    const variants = {
        hidden: { height: 0, opacity: 0, overflow: "hidden" },
        visible: { height: "auto", opacity: 1, overflow: "visible", transition: { duration: 0.6, ease: "easeInOut" } },
    };

    const toggleSummary = (key) => {
        setExpandedSummaries((prev) => ({
            ...prev,
            [key]: !prev[key]
        }));
    };
    const handleSubPartSelection = (subPart) => {
        setSelectedSubParts((prev) => {
            const isAlreadySelected = prev.some((item) => item.id === subPart.id);

            if (isAlreadySelected) {
                return prev.filter((item) => item.id !== subPart.id); // Remove if already selected
            } else {
                return [...prev, subPart]; // Add new subpart object
            }
        });
    };

    const handleSubmit = () => {
        console.log("Selected SubParts:", selectedSubParts);
    };

    return (<>
        <TableContainer component={Paper} style={{ marginTop: "10px" }}>
            <Table>
                <TableHead>
                    <TableRow >
                        <TableCell></TableCell>
                        <TableCell style={{ fontFamily: "Rubik", fontSize: "15px" }}>Item</TableCell>
                        {months.map((month) => (
                            <TableCell key={month} style={{ fontFamily: "Rubik" }}>{month}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(mockData).map((product) => (
                        <React.Fragment key={product}>
                            <TableRow sx={{ backgroundColor: "white" }}>
                                <TableCell>
                                    <IconButton onClick={() => toggleRow(product)}>
                                        {expandedRows[product] ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6">{product}</Typography>
                                </TableCell>
                                {months.map((month) => (
                                    <TableCell key={month}>
                                        <Card
                                            sx={{
                                                p: 1,
                                                backgroundColor: "#F5F8FF",
                                                border: expandedSummaries[`${product}-${month}`] ? "2px solid black" : "none",
                                                cursor: "pointer",
                                                border: "1px solid",
                                                borderRadius: "12px"
                                            }}
                                            onClick={() => toggleSummary(`${product}-${month}`)}
                                        >
                                            <Typography sx={{ color: "#0066CC", fontWeight: "500", fontFamily: "Rubik" }}>Summary</Typography>
                                            <Typography style={{ fontFamily: "Rubik" }}>Required</Typography>
                                            <Typography style={{ fontFamily: "Rubik" }} >Projected</Typography>
                                            <Typography sx={{ color: "red", fontWeight: "600", fontFamily: "Rubik" }}>To Order</Typography>
                                        </Card>
                                        {expandedSummaries[`${product}-${month}`] && (
                                        <motion.div
                                        initial="hidden"
                                        animate={expandedSummaries[`${product}-${month}`] ? "visible" : "hidden"}
                                        variants={variants}
                                        
                                    >
                                        <Box
                                            sx={{
                                                mt: 1,
                                                p: 1,
                                                backgroundColor: "#F5F8FF",
                                                border: "1px solid",
                                                borderRadius: "12px",
                                            }}
                                        >
                                            <Typography sx={{ color: "#0066CC", fontWeight: "bold", fontFamily: "Rubik" }}>Details</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Available Stock:</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Actual</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Planned</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Requested</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Ordered</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved Stock:</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved (Prod)</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved (Sub)</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved (Plan)</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Expired Stock</Typography>
                                        </Box>
                                    </motion.div>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                            {expandedRows[product] &&
                                mockData[product].parts.map((part) => (
                                    <React.Fragment key={part.id}>
                                        <TableRow sx={{ backgroundColor:"white", fontFamily: "Rubik" }}>
                                            <TableCell>
                                                <IconButton onClick={() => toggleRow(part.id)}>
                                                    {expandedRows[part.id] ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>&nbsp;&nbsp;&nbsp; {part.name}</TableCell>
                                            {months.map((month) => (
                                                <TableCell key={month}>
                                                    <Card
                                                        sx={{
                                                            p: 1,
                                                            backgroundColor: "#F5F8FF",
                                                            border: expandedSummaries[`${part.id}-${month}`] ? "2px solid black" : "none",
                                                            cursor: "pointer",
                                                            border: "1px solid",
                                                            borderRadius: "12px",
                                                        }}
                                                        onClick={() => toggleSummary(`${part.id}-${month}`)}
                                                    >
                                                        <Typography sx={{ color: "#0066CC", fontWeight: "500", fontFamily: "Rubik" }}>Summary</Typography>
                                                        <Typography style={{ fontFamily: "Rubik" }} >Required</Typography>
                                                        <Typography style={{ fontFamily: "Rubik" }}>Projected</Typography>
                                                        <Typography sx={{ color: "red", fontWeight: "bold", fontFamily: "Rubik" }}>To Order</Typography>

                                                    </Card>
                                                    {expandedSummaries[`${part.id}-${month}`] && (
                                                        <motion.div
                                                        initial="hidden"
                                                        animate={expandedSummaries[`${product}-${month}`] ? "visible" : "hidden"}
                                                        variants={variants}
                                                        
                                                    >
                                                        <Box
                                                            sx={{
                                                                mt: 1,
                                                                p: 1,
                                                                backgroundColor: "#F5F8FF",
                                                                border: "1px solid",
                                                                borderRadius: "12px",
                                                            }}
                                                        >
                                                            <Typography sx={{ color: "#0066CC", fontWeight: "bold", fontFamily: "Rubik" }}>Details</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Available Stock:</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Actual</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Planned</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Requested</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Ordered</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved Stock:</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved (Prod)</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved (Sub)</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved (Plan)</Typography>
                                                            <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Expired Stock</Typography>
                                                        </Box>
                                                    </motion.div>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {expandedRows[part.id] &&
                                            part.subParts
                                            .filter(subPart => includeExpiredStock || !subPart.expired)
                                            .map((subPart) => (
                                                <TableRow key={subPart.id} sx={{ backgroundColor: "white" }}>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={selectedSubParts.some((item) => item.id === subPart.id)} // Check if subPart exists in array
                                                            onChange={() => handleSubPartSelection(subPart)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {subPart.name}</TableCell>
                                                    {months.map((month) => (
                                                        <TableCell key={month}>
                                                            <Card
                                                                sx={{
                                                                    p: 1,
                                                                    backgroundColor: "#F5F8FF",
                                                                    border: expandedSummaries[`${subPart.id}-${month}`] ? "2px solid black" : "none",
                                                                    cursor: "pointer",
                                                                    border: "1px solid",
                                                                    borderRadius: "12px",
                                                                }}
                                                                onClick={() => toggleSummary(`${subPart.id}-${month}`)}
                                                            >
                                                                <Typography sx={{ color: "#0066CC", fontWeight: "500", fontFamily: "Rubik" }}>Summary</Typography>
                                                                <Typography style={{ fontFamily: "Rubik" }} >Required</Typography>
                                                                <Typography style={{ fontFamily: "Rubik" }}>Projected</Typography>
                                                                <Typography sx={{ color: "red", fontWeight: "bold", fontFamily: "Rubik" }}>To Order</Typography>

                                                            </Card>
                                                            {expandedSummaries[`${subPart.id}-${month}`] && (
                                                                <motion.div
                                                                initial="hidden"
                                                                animate={expandedSummaries[`${product}-${month}`] ? "visible" : "hidden"}
                                                                variants={variants}
                                                                
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        mt: 1,
                                                                        p: 1,
                                                                        backgroundColor: "#F5F8FF",
                                                                        border: "1px solid",
                                                                        borderRadius: "12px",
                                                                    }}
                                                                >
                                                                    <Typography sx={{ color: "#0066CC", fontWeight: "bold", fontFamily: "Rubik" }}>Details</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Available Stock:</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Actual</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Planned</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Requested</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Ordered</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved Stock:</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved (Prod)</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved (Sub)</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Reserved (Plan)</Typography>
                                                                    <Typography variant="body2" style={{ fontFamily: "Rubik" }}>Expired Stock</Typography>
                                                                </Box>
                                                            </motion.div>
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                    </React.Fragment>
                                ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
                variant="contained"
                onClick={handleSubmit}
                style={{
                    height: "40px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "3px",
                    alignItems: "center",
                    backgroundColor: "#050092",
                    fontFamily: "Rubik"
                }}
            >
                Submit
            </Button>
        </Box>

    </>
    );
};

export default MultiLayerTable;
