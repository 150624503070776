import axios from 'axios';

export const getAllSalesOrderAPI = async () => {
    const username = process.env.REACT_APP_SAP_USR;
    const password = process.env.REACT_APP_SAP_PWD;
    const auth = 'Basic ' + btoa(`${username}:${password}`);

    try {
        const res = await axios.get("/sap/opu/odata/sap/API_SALES_ORDER_SRV/A_SalesOrder?$format=json", {
            headers: {
                Authorization: auth,
                Accept: "*/*",
                "x-csrf-token": "fetch" ,
            },
        });

        return res;
    } catch (error) {
        console.error("Error fetching sales orders:", error);
        throw error;
    }
};
export const postNewSalesOrderAPI = async (xCsrfToken, newData) => {
    const username = process.env.REACT_APP_SAP_USR;
    const password = process.env.REACT_APP_SAP_PWD;
    const auth = 'Basic ' + btoa(`${username}:${password}`);

    try {
        const res = await axios.post("/sap/opu/odata/sap/API_SALES_ORDER_SRV/A_SalesOrder",
            newData,
            {
                headers: {
                    Authorization: auth,
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    "x-csrf-token": xCsrfToken, 
                },
            });

        // console.log(res)
        return res;
    } catch (error) {
        console.error("Error fetching sales orders:", error);
        throw error;
    }
};

export const post2SapViaErpnextAPI = async (data) => {
    let api_key = localStorage.getItem("api_key");
    let api_secret = localStorage.getItem("api_secret");

    const formData = new FormData();
    formData.append("sds_document_id", data.id);

    const res = await axios.post(
      `${localStorage.getItem(
        "base_url"
      )}/api/method/drs_backend.api.sync_sap_sales_order`,
      formData,
      {
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  };
  